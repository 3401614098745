var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "wrapper_item" },
    [
      _c("nuxt-img", {
        staticClass: "wrapper_item__avatar img-fluid",
        staticStyle: { "object-fit": "cover" },
        attrs: { format: "webp", width: "40", src: _vm.author.avatar, alt: "" },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "wrapper_item__name d-flex align-items-center flex-1 ml-2",
          attrs: { id: "most-question-" + _vm.question.id },
        },
        [
          _c(
            "nuxt-link",
            {
              directives: [
                {
                  name: "tippy",
                  rawName: "v-tippy",
                  value: {
                    delay: [1000, 200],
                    placement: "bottom-start",
                    animateFill: false,
                    animation: "scale",
                    zIndex: _vm.textContent === "" ? -1 : 30000,
                  },
                  expression:
                    "{\n        delay: [1000, 200],\n        placement: 'bottom-start',\n        animateFill: false,\n        animation: 'scale',\n        zIndex: textContent === '' ? -1 : 30000,\n      }",
                },
              ],
              staticClass: "wrapper_item__name--clamp",
              attrs: {
                to: _vm.toQuestion(_vm.question.title, _vm.question.id),
                content: `${_vm.question.title}`,
              },
              nativeOn: {
                contextmenu: function ($event) {
                  return (() => {
                    _vm.textContent = ""
                  }).apply(null, arguments)
                },
                mouseover: function ($event) {
                  return (() => {
                    _vm.textContent = _vm.$handleHover(
                      `most-question-${_vm.question.id}`,
                      _vm.question.title,
                      2,
                      17.5,
                      0
                    )
                  }).apply(null, arguments)
                },
              },
            },
            [_vm._v("\n      " + _vm._s(_vm.question.title) + "\n      ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }