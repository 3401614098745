
import { Component, Vue, Prop } from "nuxt-property-decorator";

@Component({})
export default class QuestionFeaturedItem extends Vue {
  @Prop({ type: Object, required: true, default: () => ({}) }) question;

  textContent: string = "";

  // author = {};

  // created() {
  // 	if (!(this.$store.state.profile.queueIDs as string[]).includes(this.question.authorId || '')) {
  // 	 this.$store.dispatch('profile/addProfileIDToQueue', this.question.authorId);
  // 	}
  // }
  // mounted() {
  // 	this.$watch(() => this.$store.state.profile.profiles, (newVal, oldVal) => {
  // 		if (!Array.isArray(newVal) || !newVal.length) {
  // 			return;
  // 		}
  // 		this.author = newVal.find(member => member.userID == this.question.authorId) ?
  //             newVal.find(member => member.userID == this.question.authorId) : {name: '', avatar: ''};
  // 	}, {deep: true, immediate: true});
  // }
  toQuestion(title, id) {
    return (this as any).localePath({
      name: "forums-slug",
      params: {
        slug: this.$formatSlugUrl(title || "", this.$fromUUID(id || "")),
      },
    });
  }

  get author() {
    return this.question.author || { name: "", avatar: "" };
  }
}
